.cohere-description {
    color: rgb(0, 0, 0);
    text-align: justify;
}

.cohere-description:hover {
    background-color: #dadae2;
    border-radius: 15px;
    cursor: pointer;
}

.ThreatContent-box {
    text-align: right; /* Align text to the right */
    width: 100%; /* Ensure content box takes the full width */
  }
  
  .ThreatContent-box h3 {
    font-size: 0.85vw;
    letter-spacing: 0.02857em;
    font-weight: 700;
    margin: 0px;
  }
  
  .ThreatContent-box h4 {
    font-size: 1rem;
    color: rgb(87, 12, 24);
    font-weight: 700;
    letter-spacing: 0.00735em;
    margin: 0px;
  }

  .ThreatStats-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align content to the right */
    background-color: white;
    border-radius: 10px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;
    width: 24%; /* Adjust width to provide good spacing on large screens */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-bottom: 20px; /* Space between boxes on small screens */
  }
  
  .ThreatStats-box:hover {
    cursor: pointer;
    background-color: #f1f1f1;
  
  }
  
  .ThreatIcon-box {
    position: absolute;
    top: -20px; /* Move it slightly upwards to overlap the white box */
    left: 0px; /* Align it to the left side */
    padding: 15px;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
  }

  .ThreatHrLine{
    flex-shrink: 0;
    border-top: 0px solid rgba(0, 0, 0, 0.12);
    border-right: 0px solid rgba(0, 0, 0, 0.12);
    border-left: 0px solid rgba(0, 0, 0, 0.12);
    background-color: transparent;
    height: 0.0625rem;
    border-bottom: none;
    opacity: 0.25;
    background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important;
  }
@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');

.AssessmentContent {
    margin-left: 20rem; /* Offset by sidebar width plus margin */
    padding: 20px;
    width: calc(100% - 20rem); /* Adjust width to account for sidebar and margins */
    box-sizing: border-box; /* Ensures padding doesn't add to width */
    font-family: "SUSE", sans-serif;
}
.AsessmentContainerBox {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
.AIicons {
    font-size: 24px; /* Increase icon size */
    color: #9d47d4; /* Change icon color */
    background-color: #f3e6fa; /* Set background color */
    padding: 8px; /* Add padding */
    border-radius: 4px; /* Make background circular */
    margin-right: 8px; /* Add some space between the icon and text */
}
.AIicon2 {
    font-size: 32px; /* Increase icon size */
    color: #4045fb; /* Change icon color */
    padding: 8px; /* Add padding */
    margin-right: 8px; /* Add some space between the icon and text */
}
/* Collapsible Container */
.collapsible {
    margin: 10px 0;
    border-radius: 10px;
    width: 100%; /* Takes full width of parent */
    max-width: 100%; /* Prevents overflow */
}

/* Collapsible Header */
.collapsible-header {
    background-color: #fbf4fe;
    border: 2px solid #e2d6fd;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    padding-right: 7px;
    gap: 10px;
    font-family: "SUSE", sans-serif;
    font-weight: 500;
    font-size: 1.05rem;
}

.collapsible-header-2nd {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-radius: 10px;
    gap: 10px;
    font-family: "SUSE", sans-serif;
}

/* Collapsible Content */
.collapsible-content {
    padding: 10px;
    max-width: 100%; /* Ensures content stays within the parent's width */
    box-sizing: border-box;
    word-wrap: break-word; /* Prevents long content from overflowing */
}

/* Status Section */
.collapsible-status {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Kebab Menu */
.kebab-menu {
    cursor: pointer;
    font-size: 20px;
    padding-left: 10px;
}

.kebab-menu:hover {
    background-color: #dadae2 ;
    border-radius: 10px;
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 18px;
}

.modal-content input[type="number"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.modal-content textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    resize: none;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
}

.modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-actions button:first-child {
    background: #f0f0f0;
}
.modal-actions button:first-child:hover {
    background: #cfcfcf;
}

.modal-actions button:last-child {
    background: #6c63ff;
    color: white;
}
.modal-actions button:last-child:hover {
    background: #6144cc;
}

.progress-bar-container {
    width: 150px;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.progress-bar {
    height: 100%;
    transition: width 0.3s ease;
    background-color: #f04438 ;
}

.assessment-table {
    border-collapse: collapse;
    width: 100%;
}
  
.assessment-table th, .assessment-table td {
    padding: 8px;
}

.question-text {
    white-space: normal;  /* Preserves line breaks */
    color: rgb(0, 0, 0);
    text-align: justify;
}

/*.question-text:hover {
    background-color: #dadae2;
    border-radius: 15px;
    cursor: pointer;
}*/


  
.information-box {
    width: 45%; 
    color: rgb(0, 0, 0);
    font-family: 'Maven Pro', sans-serif;
}

.header-cell{
    text-align: left;
    padding: 6px; 
    font-size: 14px;
    color: rgb(0, 0, 0);
}

.arrow {
    display: flex;
    align-items: center; /* Aligns the icons and text vertically in the center */
    gap: 10px; /* Adjust the value to control the space between icons and the title */
    padding: 7px;
    cursor: pointer;
}
.sixArrow {
    display: flex;
    align-items: center; /* Aligns the icons and text vertically in the center */
    gap: 10px; /* Adjust the value to control the space between icons and the title */
    padding: 7px;
}  
.arrow span {
    margin-left: 5px; /* Adds space between the arrow and the title */
}

.arrow:hover{
    background-color: #dadae2 ;
    border-radius: 15px;
    padding: 7px;
}



.fileButton {
    font-size: 13px;
    padding: 8px 10px;
    background-color: #7a5fff;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
  }
  
  .fileButton.remove {
   margin-left: 5px;
   margin-top: 5px;
  }
  
  
  .fileButton:hover{
    background-color: #6144cc;
  }

.custom-file-upload {
    display: inline-block;
    padding: 6px 8px;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    background-color: #7a5fff;
    border-radius: 5px;
    border: 2px solid transparent;
    cursor: pointer;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
}

/* Hover and focus effects */
.custom-file-upload:hover,
.custom-file-upload:focus {
    background-color: #6144cc;
    border-color: #6144cc;
}

input[type="file"] {
    display: none; /* Hide the default file input */
}

.titleText {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 2rem;  /* Slightly larger font for emphasis */
    font-weight: bold;  /* Makes the title stand out */
    color: #2E3A59;  /* A dark professional color */
}

.sloganText {
    text-align: center;
    font-size: 1.1rem;  /* Increased size for better readability */
    font-style: italic;
    margin-top: 0;
    color: #555;  /* Lighter shade for contrast with the title */
    letter-spacing: 0.5px;  /* Subtle letter spacing for clarity */
}


.closeButton{
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: #6c63ff;
    color: white;
    margin-top: 10px;
}
.closeButton:hover{
    background: #6144cc;
}



.AiheaderPop {
    margin: -70px -20px 20px; /* Negative margin to pull the header upwards */
    padding: 30px;
    text-align: center;
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
    position: relative; /* Ensure it stays in place relative to .auth-box */
    z-index: 1; /* Make sure it appears above the white box */
}

.AIContainerBoxPop {
    margin-left: 20rem; /* Offset by sidebar width plus margin */
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 60%;
  }
  
  .AicontentPop {
    box-sizing: border-box; /* Ensures padding doesn't add to width */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: rgb(0, 0, 0);
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1050;
    width: 100%;
    height: 100%;
  }
  .chatbot-containerPop {
    width: 100%;
    margin: 25px auto;
    background-color: #f5f5f5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow: hidden;
  }

  .no-scroll {
    overflow: hidden;
  }

  .collapsible-status  input[type='radio']{
    display: none;
  
  }
  .collapsible-status  label span{
    display: inline-flex;
    align-items: center;
    padding: 10px 20px 10px 10px;
    border-radius: 31px;
    transition: .25s ease;
  }
  
  .collapsible-status label span::before{
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    transition: .25s ease;
    box-shadow: inset 0 0 0 2px #4045fb; 
  }
  
  .collapsible-status input[type="radio"]:checked + span:before{
    box-shadow: inset 0 0 0 7px #4045fb;
  }
  

  .Assessmentcheckbox {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border: 2px solid #ccc;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    vertical-align: middle;
    transition: border-color 0.3s ease, background-color 0.3s ease; /* Smooth transition */
  }
  
  /* Tick mark (shown when checked) */
  .Assessmentcheckbox::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
  }
  
  /* Show the tick mark when checked */
  input[type="checkbox"]:checked + .Assessmentcheckbox::after {
    display: block;
  }
  
  /* Change the checkbox appearance when checked */
  input[type="checkbox"]:checked + .Assessmentcheckbox {
    background-color: #6c63ff;
    border-color: #6c63ff;
    
  }
  
  /* Add hover effect: Change border color */
  .Assessmentcheckbox:hover {
    border-color: #6c63ff;
    
  }

  .radioText:hover{
    cursor: pointer;
  }

  .copyright{
    text-align: center;
  }

  .copyright.sign{
    color: white;
  }
@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');

.AiDatabaseContent {
  margin-left: 20rem; /* Offset by sidebar width plus margin */
  padding: 20px;
  width: calc(100% - 20rem); /* Adjust width to account for sidebar and margins */
  box-sizing: border-box; /* Ensures padding doesn't add to width */
  font-family: "SUSE", sans-serif;
}

.AiDatabase {
    padding: 20px;
  }
  
  .aiDatabaseTitle {
    color: #333;
  }
  
  .incident-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .incident {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .incident h2 a {
    color: #007bff;
    text-decoration: none;
  }
  
  .incident h2 a:hover {
    text-decoration: underline;
  }
  
  .incident p {
    margin: 5px 0;
  }
  